<template>
    <b-modal v-model="openModel" title="Add Main Victim" size="lg" no-close-on-backdrop no-close-on-esc hide-header-close>

        <div class="d-flex justify-content-between">
            <b>Name of Business*</b>
            <b-form-input v-model="person.nameOfBusiness" :style="isRequiredInput(person.nameOfBusiness)"/>
        </div>
        <sup v-if="complainant === false" style="color: red">You must be reporting for a business to be a complainant.</sup>
        <div v-else style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Business Address*</b>
            <b-form-input v-model="person.address"
                          :style="isRequiredInput(person.address)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Phone Number*</b>
            <PhoneInput v-model="person.phone" :inputStyle="isRequiredInput(person.phone)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Email*</b>
            <EmailInput v-model="person.email" :input-style="isRequiredInput(person.email)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Preferred Method of Contact if needed</b>
            <b-form-select v-model="person.prefCommunication" :options="['By Email', 'By Phone', 'In Person']"/>
        </div>
        <div style="height: 24px"/>

        <template v-slot:modal-footer>
            <div class="d-flex justify-content-end w-100">
                <b-button size="sm" variant="secondary" @click="close" style="margin-right: 10px">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submit" :disabled="!ready">
                    Submit
                </b-button>
            </div>
        </template>

    </b-modal>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";
import reportingStore from "@/store/index.js";
import PhoneInput from "@/components/PhoneInput";
import EmailInput from "@/components/EmailInput";

export default {
    name: "VictimInformation",
    mixins: [FormMixin],
    components: {PhoneInput, EmailInput},
    props: {
        open: Boolean
    },
    data() {
        return {
            template: {
                association: 'Victim',
                nameOfBusiness: null,
                address: null,
                phone: null,
                email: null,
                prefCommunication: 'By Email',
            },
            complainant: null,
            person: null
        }
    },
    created() {
        if (this.report.mainVictim) {
            this.person = Object.assign({}, this.report.mainVictim);
        } else {
            this.person = Object.assign({}, this.template);
        }
    },
    methods: {
        isEmail(val) {
            return EmailInput.methods.isEmail(val)
        },
        isNumber(val) {
            return PhoneInput.methods.isNumber(val)
        },
        submit() {
            this.report.mainVictim = this.person;
            reportingStore.dispatch('updateReport', this.report);
            this.close();
        },
        close() {
            this.$emit('close');
            this.person = Object.assign({}, this.template);
        }
    },
    computed: {
        openModel() {
            return this.open;
        },
        ready() {
            return this.person.nameOfBusiness && this.person.address && this.person.email
                   && this.isEmail(this.person.email) && this.person.phone && this.isNumber(this.person.phone)
        }
    }
}
</script>

<style scoped>

</style>


