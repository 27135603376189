<template>
    <b-modal v-model="openModel" title="Add Vehicle" size="lg" no-close-on-backdrop no-close-on-esc hide-header-close>

        <div class="d-flex justify-content-between">
            <b>Association*</b>
            <b-form-select v-model="vehicle.association" :options="['Suspect', 'Mention']" :style="isRequiredInput(vehicle.association)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Owner</b>
            <b-form-input v-model="vehicle.owner"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>License Plate</b>
            <b-form-input v-model="vehicle.plate"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Type of Vehicle</b>
            <b-form-select v-model="vehicle.type" :options="['Automobile', 'SUV', 'Truck', 'Van', 'Motorcycle', 'Other (Explain)']"/>
        </div>
        <div style="height: 24px"/>

        <div v-if="vehicle.type === 'Other (Explain)'">
            <div class="d-flex justify-content-between">
                <b></b>
                <b-form-input v-model="vehicle.typeOther"/>
            </div>
            <div style="height: 24px"/>
        </div>

        <div class="d-flex justify-content-between">
            <b>Make/Model</b>
            <b-form-input v-model="vehicle.makeModel"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Color</b>
            <b-form-input v-model="vehicle.color"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Number of Doors</b>
            <b-form-select v-model="vehicle.doors" :options="['2 door', '4 door', 'Other']"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Descriptive Information</b>
            <b-form-textarea v-model="vehicle.desc"/>
        </div>
        <div style="height: 24px"/>

        <template v-slot:modal-footer>
            <div class="d-flex justify-content-end w-100">
                <b-button size="sm" variant="secondary" @click="close" style="margin-right: 10px">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submit" :disabled="!ready">
                    Submit
                </b-button>
            </div>
        </template>

    </b-modal>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";

export default {
    name: "AddVehicle",
    mixins: [FormMixin],
    props: ['open', 'index'],
    data() {
        return {
            template: {
                association: null,
                owner: null,
                plate: null,
                type: null,
                typeOther: null,
                makeModel: null,
                color: null,
                doors: null,
                desc: null,
            },
            vehicle: null
        }
    },
    created() {
        if (this.index !== null && this.report.vehicles
            && Array.isArray(this.report.vehicles) && this.report.vehicles[this.index]) {
            this.vehicle = Object.assign({}, this.report.vehicles[this.index]);
        } else {
            this.vehicle = Object.assign({}, this.template);
        }
    },
    methods: {
        submit() {
            this.addArrayElement('vehicles', this.vehicle, this.index);
            this.close();
        },
        close() {
            this.$emit('close');
            this.vehicle = Object.assign({}, this.template);
        }
    },
    computed: {
        openModel() {
            return this.open;
        },
        ready() {
            return (this.vehicle.association);
        }
    }
}
</script>

<style scoped>

</style>



