<template>
    <div>
        <div style="width: 100%; height: 100%;" class="d-flex justify-content-center" id="pdf">
            <embed :src="pdfPrint" type="application/pdf" width="1200" :height="containerHeight"/>
        </div>
    </div>
</template>

<script>
import reportingStore from "@/store/index.js";

export default {
    name: "Pdf",
    data() {
        return {
            containerHeight: 150,
            pdfPrint: null
        }
    },
    async created() {
        this.pdfPrint = reportingStore.getters.getPdf;
        window.addEventListener("resize", this.resizePdf);
    },
    mounted() {
        this.containerHeight = window.innerHeight - 225;
    },
    destroyed() {
        window.removeEventListener("resize", this.resizePdf);
    },
    methods: {
        resizePdf() {
            this.containerHeight = window.innerHeight - 225;
        },
    }

}
</script>

<style scoped>

</style>