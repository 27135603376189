<template>
    <div>
        <b-form-input v-model="input" v-mask="'(###) ###-####'" placeholder="(___) ___-____" @keypress="onlyNumber" maxlength="14"
                      style="position: relative" :style="inputStyle || (!validInput && value ? 'border-color: red' : '')"/>
        <b-form-text v-if="!validInput && value" text-variant="danger" class="helpText">Invalid Number</b-form-text>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
Vue.use(VueMask);

export default {
    name: "PhoneInput",
    props: ['value', 'inputStyle'],
    created() {
        this.validInput = this.isNumber(this.input);
        this.$emit('input', this.input)
    },
    data () {
        return {
            validInput: false,
            input: this.value
        }
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        isNumber(val) {
            return (/(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g
            ).test(val)
        }
    },
    watch: {
        input() {
            this.validInput = this.isNumber(this.input)
            this.$emit('input', this.input)
        }
    }
}
</script>

<style scoped>
.helpText {
    position: absolute;
    transform: translateY(-5px);
}
</style>