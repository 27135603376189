<template>
    <div>

        <div style="text-align: center">
            In order to be able to use this form to report an incident, the incident must have occurred in the City of
            Beloit and must be belated.  If the incident did not occur in the City of Beloit you must contact the law
            enforcement agency with jurisdiction of where the incident occurred.
        </div>
        <div style="height: 24px"/>

        <div v-for="(question, index) in questions" :key="index">
            <div class="d-flex justify-content-between">
                <b>{{question.q}}</b>
                <b-form-radio-group v-model="report.gettingStarted[index]" :options="yesNoRadioOptions" :class="isRequiredRadio(report.gettingStarted[index])"/>
            </div>
            <sup v-if="report.gettingStarted[index] !== null && report.gettingStarted[index] !== question.a" style="color: red; text-align: left">
                You will not be able to continue with self reporting. {{errors[question.error]}}
            </sup>
            <div v-else style="height: 24px"/>
        </div>

        <div class="d-flex justify-content-between">
            <b-button size="sm" @click="changePage(0)">Back</b-button>
            <b-button size="sm" :disabled="!ready" @click="changePage(2)">Next</b-button>
        </div>

    </div>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";

export default {
    name: "GettingStarted",
    mixins: [FormMixin],
    data() {
        return {
            questions: [
                {q: 'Is this an emergency?', a: false,  error: 0},
                {q: 'Is anyone injured?', a: false, error: 0},
                {q: 'Did this happen in the City of Beloit?', a: true, error: 1},
                {q: 'Was a weapon used or threatened to be used?', a: false, error: 2},
                {q: 'Was any level of force used or threatened?', a: false, error: 2},
                {q: 'Was any consent given for your property to be taken or damaged?', a: false, error: 2}
            ],
            errors: [
                'Call or text 911 immediately.',
                'Contact the jurisdiction the incident occurred.',
                'Call 608-757-2244 for assistance.'
            ]
        };
    },
    created() {
        this.addElements({gettingStarted: new Array(this.questions.length).fill(null)})
    },
    computed: {
        ready() {
            return this.report.gettingStarted.every((ans, index) => {
                return ans !== null && ans === this.questions[index].a
            });
        }
    }
}
</script>
