<template>
    <div>

        <div v-if="questions !== null">
            <div v-for="(question, index) in questions" :key="index + 'generalQuestions'">
                <div class="d-flex justify-content-between">
                    <b>{{question}}</b>
                    <b-form-radio-group v-model="report.generalQuestions[index]" :options="yesNoRadioOptions" :class="isRequiredRadio(report.generalQuestions[index])"/>
                </div>
                <sup v-if="report.generalQuestions[index] && manageExceptions(question)" style="color: red">
                    You will not be able to continue with self reporting. Call (608) 757-2244 for assistance.
                </sup>
                <div v-else style="height: 24px"/>
            </div>
        </div>

        <div v-if="questions === null">
            <div class="d-flex justify-content-between">
                <b>Is this violation occurring right now?</b>
                <b-form-radio-group v-model="report.generalQuestions[0]" :options="yesNoRadioOptions" :class="isRequiredRadio(report.generalQuestions[0])"/>
            </div>
            <div style="height: 24px"/>

            <div v-if="report.generalQuestions[0]">
                <div class="d-flex justify-content-between">
                    <b>Is this an on-going violation?</b>
                    <b-form-radio-group v-model="report.generalQuestions[1]" :options="yesNoRadioOptions" :class="isRequiredRadio(report.generalQuestions[1])"/>
                </div>
                <sup v-if="!report.generalQuestions[1]" style="color: red">You will not be able to continue with self reporting. Call (608) 757-2244 for assistance.</sup>
                <div v-else style="height: 24px"/>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <b-button size="sm" @click="changePage(2)">Back</b-button>
            <b-button size="sm" :disabled="!ready" @click="changePage(4)">Next</b-button>
        </div>

    </div>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";

export default {
    name: "GeneralQuestions",
    mixins: [FormMixin],
    data() {
        return {
            allQuestions: {
                'Theft': [
                    "Do you have a suspect?",
                    "Was the theft of a firearm, prescription medication, or vehicle?",
                    "Is the amount of stolen items over $2,500.00?"
                ],
                'Vandalism': [
                    "Do you have a suspect?",
                    "Is the property that is vandalized government property?",
                    "Can the vandalism be construed as a bias, or hate crime?",
                    "Is the property damaged over $2,500.00 to replace?"
                ],
                'Lost Property': [
                    "Is the lost property a firearm or vehicle?"
                ],
                'Fraud/Scam': [
                    "Do you have a suspect?",
                ],
                'Shoplifting': [
                    "Do you have a suspect?",
                    "Do you have picture and/or video of the suspect where the suspect is identifiable?",
                    "Do you have the license plate number of the vehicle the suspect left in?",
                    "Was the theft of merchandise total over $500.00?"
                ]
            }
        }
    },
    created() {
        this.addElements({generalQuestions: []});
    },
    methods: {
        manageExceptions(question) {
            if (this.report.reportType === "Shoplifting") {
                return question !== "Do you have a suspect?" &&
                       question !== "Do you have picture and/or video of the suspect where the suspect is identifiable?" &&
                       question !== "Do you have the license plate number of the vehicle the suspect left in?";
            } else {
                return true;
            }
        }
    },
    computed: {
        questions() {
            if (this.report.reportType === 'Drug Violation') {
                return null;
            } else if (this.allQuestions[this.report.reportType]) {
                return this.allQuestions[this.report.reportType];
            } else {
                console.log('Error: unknown report type');
                return null;
            }
        },
        ready() {
            if (this.report.reportType === 'Drug Violation') {
                return this.report.generalQuestions[0] === false || (this.report.generalQuestions[0] && this.report.generalQuestions[1]);
            } else if (this.report.reportType === "Shoplifting") {
                return this.report.generalQuestions[3] === false;
            } else if (this.allQuestions[this.report.reportType]) {
                return this.questions.every((q, index) => this.report.generalQuestions[index] === false);
            } else {
                return false;
            }
        }
    }
}
</script>

<style scoped>

</style>

