
<template>
    <b-modal v-model="openModel" title="Add Property" size="lg" no-close-on-backdrop no-close-on-esc hide-header-close>

        <div class="d-flex justify-content-between">
            <b>Status*</b>
            <b-form-select v-model="property.status" :style="isRequiredInput(property.status)" :options="getOptions()"
                           :disabled="getOptions().length < 2"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Owner</b>
            <b-form-input v-model="property.owner"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Item*</b>
            <b-form-input v-model="property.item" :style="isRequiredInput(property.item)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Brand</b>
            <b-form-input v-model="property.brand"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Model</b>
            <b-form-input v-model="property.model"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Year</b>
            <b-form-input v-model="property.year"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Serial Number</b>
            <b-form-input v-model="property.serialNumber"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Quantity*</b>
            <b-form-input v-model="property.quantity" type="number" :style="isRequiredInput(property.quantity)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Value* (To best of your knowledge)</b>
            <b-form-input v-model="property.value" :style="isRequiredInput(property.value)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Descriptive Information</b>
            <b-form-textarea v-model="property.desc"/>
        </div>
        <div style="height: 24px"/>

        <template v-slot:modal-footer>
            <div class="d-flex justify-content-end w-100">
                <b-button size="sm" variant="secondary" @click="close" style="margin-right: 10px">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submit" :disabled="!ready">
                    Submit
                </b-button>
            </div>
        </template>

    </b-modal>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";
import reportingStore from "@/store";

export default {
    name: "MainProperty",
    mixins: [FormMixin],
    props: ['open'],
    data() {
        return {
            template: {
                status: null,
                owner: null,
                item: null,
                brand: null,
                model: null,
                year: null,
                serialNumber: null,
                quantity: null,
                value: null,
                desc: null,
            },
            property: null
        }
    },
    created() {
        if (this.report.mainProperty) {
            this.property = Object.assign({}, this.report.mainProperty);
        } else {
            this.property = Object.assign({}, this.template);
            if (this.report.reportType !== 'Fraud/Scam') {
                this.property.status = this.getOptions()[0]
            }
        }
    },
    methods: {
        submit() {
            this.report.mainProperty = this.property;
            reportingStore.dispatch('updateReport', this.report);
            this.close();
        },
        close() {
            this.$emit('close');
            this.property = Object.assign({}, this.template);
        },
        getOptions() {
            switch (this.report.reportType) {
                case 'Theft':
                    return ['Stolen'];
                case 'Vandalism':
                    return ['Destroyed/Damaged/Vandalized'];
                case 'Lost Property':
                    return ['Lost Property'];
                case 'Fraud/Scam':
                    return ['Stolen', 'Attempted Stolen'];
                case 'Shoplifting':
                    return ['Stolen'];
                default:
                    return ['Options Not Found'];
            }
        }
    },
    computed: {
        openModel() {
            return this.open;
        },
        ready() {
            return !(!this.property.status || !this.property.item
                || !this.property.quantity || !this.property.value);
        }
    },
}
</script>

<style scoped>

</style>



