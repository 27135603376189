<template>
    <div>

        <div class="d-flex justify-content-between">
            <b>Incident Location*</b>
            <b-form-input v-model="report.incidentLocation" :style="isRequiredInput(report.incidentLocation)"/>
        </div>
        <div v-if="report.reportType === 'Lost Property'" class="d-flex justify-content-end" style="height: 24px; transform: translateY(12px)">
            <sup style="width: 400px">if unsure, use home address</sup>
        </div>
        <div v-else style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Date of incident*</b>
            <DateInput v-model="report.dateOfIncident" :inputStyle="isRequiredInput(report.dateOfIncident)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Time of incident*</b>
            <b-form-timepicker v-model="report.timeOfIncident" :style="isRequiredInput(report.timeOfIncident)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b-button size="sm" @click="changePage(3)">Back</b-button>
            <b-button size="sm" :disabled="!ready" @click="changePage(5)">Next</b-button>
        </div>

    </div>
</template>

<script>

import {FormMixin} from "@/reportComponents/FormMixin";
import DateInput from "@/components/DateInput";

export default {
    name: "GeneralQuestionsCont",
    components: {DateInput},

    mixins: [FormMixin],
    created() {
        this.addElements(['incidentLocation', 'timeOfIncident', 'timeOfIncident']);
    },
    computed: {
        ready() {
            return !(!this.report.incidentLocation || !this.report.dateOfIncident || !this.report.timeOfIncident);
        }
    },
}
</script>

<style scoped>

</style>
