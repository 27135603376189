<template>
    <div>
        <div style="max-height: 700px; overflow-y: auto; margin: -20px; padding:20px">
            <div class="d-flex">
                <h4>Main Victim*</h4>
                <div style="cursor: pointer" v-if="!report.mainVictim">
                    <b-badge size="sm" @click="mainVictimModal = true"><i class="fa fa-plus"/> Add</b-badge>
                </div>
            </div>
            <b-table v-if="report.mainVictim" striped hover :items="[report.mainVictim]" :fields="mainVictimFields" :small="true">
                <template #cell(Edit) class="d-flex">
                    <div style="cursor: pointer; margin-right: 5px; display: inline-block">
                        <b-badge><fa icon="edit" @click="mainVictimModal = true"/></b-badge>
                    </div>
                    <div style="cursor: pointer; display: inline-block">
                        <b-badge><fa icon="trash" @click="deleteMainVictim"/></b-badge>
                    </div>
                </template>
                <template #cell(dob)="data" class="d-flex">
                    <div v-if="data.item.dob !== null">{{data.item.dob}}</div>
                    <div v-else>{{data.item.dob}}</div>
                </template>
            </b-table>
            <div v-else class="errorCenter"><i>Main victim required.</i></div>
            <fraud-scam-vandalism-victim-information v-if="mainVictimModal && (report.reportType === 'Vandalism' || report.reportType === 'Fraud/Scam')"
                                                     v-model="report" :open="mainVictimModal" @close="mainVictimModal = false"/>
            <theft-lost-property-victim-information v-else-if="mainVictimModal && (report.reportType === 'Theft' || report.reportType === 'Lost Property')"
                                      v-model="report" :open="mainVictimModal" @close="mainVictimModal = false"/>
            <shoplifting-victim-information v-else-if="mainVictimModal && report.reportType === 'Shoplifting'" v-model="report"
                                          :open="mainVictimModal" @close="mainVictimModal = false"/>
            <drug-victim-information v-else-if="mainVictimModal && report.reportType === 'Drug Violation'" v-model="report"
                                     :open="mainVictimModal" @close="mainVictimModal = false"/>

            <div v-if="report.reportType !== 'Drug Violation'">
                <div class="d-flex">
                    <h4>Main Property*</h4>
                    <div style="cursor: pointer" v-if="!report.mainProperty">
                        <b-badge size="sm" @click="mainPropertyModal = true"><i class="fa fa-plus"/> Add</b-badge>
                    </div>
                </div>
                <b-table v-if="report.mainProperty" striped hover :items="[report.mainProperty]" :fields="propertyFields" :small="true">
                    <template #cell(Edit)>
                        <div style="cursor: pointer; margin-right: 5px; display: inline-block">
                            <b-badge><fa icon="edit" @click="mainPropertyModal = true"/></b-badge>
                        </div>
                        <div style="cursor: pointer; display: inline-block">
                            <b-badge><fa icon="trash" @click="deleteMainProperty"/></b-badge>
                        </div>
                    </template>
                </b-table>
                <div v-else class="errorCenter"><i>Main property required.</i></div>
                <main-property v-if="mainPropertyModal" v-model="report" :open="mainPropertyModal"
                              @close="mainPropertyModal = false"/>
            </div>


            <div class="d-flex">
                <h4>People</h4>
                <div style="cursor: pointer">
                    <b-badge size="sm" @click="personModal = true; personIndex = null"><i class="fa fa-plus"/> Add</b-badge>
                </div>
            </div>
            <b-table v-if="report.people && Array.isArray(report.people) && report.people.length > 0" :small="true"
                     striped hover :items="report.people" :fields="personFields">
                <template #cell(Edit)="data">
                    <div style="cursor: pointer; margin-right: 5px; display: inline-block">
                        <b-badge><fa icon="edit" @click="personModal = true; personIndex = data.index"/></b-badge>
                    </div>
                    <div style="cursor: pointer; display: inline-block">
                        <b-badge><fa icon="trash" @click="deleteArrayElement('people', data.index)"/></b-badge>
                    </div>
                </template>
                <template #cell(dob)="data" class="d-flex">
                    <div v-if="data.item.dob !== null">{{data.item.dob}}</div>
                    <div v-else>{{data.item.dob}}</div>
                </template>
            </b-table>
            <div v-else class="centerText"><i>No additional people added.</i></div>
            <additional-person v-if="personModal" :index="personIndex" v-model="report" :open="personModal"
                               @close="personModal = false; personIndex = null"/>

            <div class="d-flex">
                <h4>Vehicles</h4>
                <div style="cursor: pointer">
                    <b-badge size="sm" @click="vehicleModal = true; vehicleIndex = null"><i class="fa fa-plus"/> Add</b-badge>
                </div>
            </div>
            <b-table v-if="report.vehicles && Array.isArray(report.vehicles) && report.vehicles.length > 0" :small="true"
                     striped hover :items="report.vehicles" :fields="vehicleFields">
                <template #cell(Edit)="data">
                    <div style="cursor: pointer; margin-right: 5px; display: inline-block">
                        <b-badge><fa icon="edit" @click="vehicleModal = true; vehicleIndex = data.index"/></b-badge>
                    </div>
                    <div style="cursor: pointer; display: inline-block">
                        <b-badge><fa icon="trash" @click="deleteArrayElement('vehicles', data.index)"/></b-badge>
                    </div>
                </template>
            </b-table>
            <div v-else class="centerText"><i>No vehicles added.</i></div>
            <add-vehicle v-if="vehicleModal" :index="vehicleIndex" v-model="report" :open="vehicleModal"
                         @close="vehicleModal = false; vehicleIndex = null"/>

            <div v-if="report.reportType !== 'Drug Violation'">
                <div class="d-flex">
                    <h4>Additional Property</h4>
                    <div style="cursor: pointer">
                        <b-badge size="sm" @click="propertyModal = true; propertyIndex = null"><i class="fa fa-plus"/> Add</b-badge>
                    </div>
                </div>
                <b-table v-if="report.property && Array.isArray(report.property) && report.property.length > 0" :small="true"
                         striped hover :items="report.property" :fields="propertyFields">
                    <template #cell(Edit)="data">
                        <div style="cursor: pointer; margin-right: 5px; display: inline-block">
                            <b-badge><fa icon="edit" @click="propertyModal = true; propertyIndex = data.index"/></b-badge>
                        </div>
                        <div style="cursor: pointer; display: inline-block">
                            <b-badge><fa icon="trash" @click="deleteArrayElement('property', data.index)"/></b-badge>
                        </div>
                    </template>
                </b-table>
                <div v-else class="centerText"><i>No additional property added.</i></div>
                <add-property v-if="propertyModal" :index="propertyIndex" v-model="report" :open="propertyModal"
                              @close="propertyModal = false; propertyIndex = null"/>
            </div>
        </div>

        <div class="d-flex justify-content-between" style="padding-top: 20px">
            <b-button size="sm" @click="changePage(4)">Back</b-button>
            <b-button size="sm" :disabled="!ready()" @click="changePage(6)">Next</b-button>
        </div>

    </div>
</template>
<script>
import {FormMixin} from "@/reportComponents/FormMixin";
import TheftLostPropertyVictimInformation from "@/reportComponents/TheftLostProperty/VictimInformation";
import FraudScamVandalismVictimInformation from "@/reportComponents/FraudScamVandalism/VictimInformation";
import ShopliftingVictimInformation from "@/reportComponents/Shoplifting/VictimInformation";
import DrugVictimInformation from "@/reportComponents/DrugViolation/VictimInformation";
import MainProperty from "@/reportComponents/Shared/MainProperty";
import AdditionalPerson from "@/reportComponents/Shared/AdditionalPerson";
import AddVehicle from "@/reportComponents/Shared/AddVehicle";
import AddProperty from "@/reportComponents/Shared/AddProperty";
import Vue from "vue";

export default {
    name: "PeoplePropertyVehicleOverview",
    components: {
        MainProperty,
        AddProperty,
        AddVehicle,
        AdditionalPerson,
        TheftLostPropertyVictimInformation,
        FraudScamVandalismVictimInformation,
        ShopliftingVictimInformation,
        DrugVictimInformation
    },
    mixins: [FormMixin],
    data() {
        return {
            mainVictimModal: false,
            personFields: ['association', 'last', 'first', 'middleInt', 'dob', 'sex', 'raceType', 'address', 'phone', 'email', 'desc', 'Edit'],
            personModal: false,
            personIndex: null,
            vehicleFields: ['association', 'owner', 'plate', 'type', 'makeModel', 'color', 'doors', 'desc', 'Edit'],
            vehicleModal: false,
            vehicleIndex: null,
            propertyFields: ['status', 'owner', 'item', 'brand', 'model', 'year', 'serialNumber', 'quantity', 'value', 'desc', 'Edit'],
            propertyModal: false,
            mainPropertyModal: false,
            propertyIndex: null,
        };
    },
    methods: {
        deleteMainVictim() {
            Vue.delete(this.report, 'mainVictim');
        },
        deleteMainProperty() {
            Vue.delete(this.report, 'mainProperty');
        },
        ready() {
            if (this.report.reportType === "Drug Violation") {
                return this.report.mainVictim !== undefined;
            } else {
                return (this.report.mainVictim && this.report.mainProperty);
            }
        },
    },
    computed: {
        mainVictimFields() {
            if (this.report.reportType === "Vandalism" || this.report.reportType === "Fraud/Scam") {
                return ['association', 'nameOfBusiness', 'last', 'first', 'middleInt', 'dob', 'address', 'phone', 'email', 'prefCommunication', 'Edit'];
            } else if (this.report.reportType === "Shoplifting") {
                return ['nameOfBusiness', 'address', 'phone', 'email', 'prefCommunication', 'Edit'];
            } else {
                return ['last', 'first', 'middleInt', 'dob', 'address', 'phone', 'email', 'prefCommunication', 'Edit'];
            }
        }
    }
}
</script>

<style scoped>
h4 {
    padding-right: 6px;
    font-weight: bold;
    margin: 0;
}
b-badge {
    height: 20px;
    width: fit-content;
    transform: translateY(-2px);
}
.errorCenter {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    color: red;
}
.centerText {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

</style>
