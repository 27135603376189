<template>
    <b-modal v-model="openModel" title="Add Additional Person" size="lg" no-close-on-backdrop no-close-on-esc hide-header-close>

        <div class="d-flex justify-content-between">
            <b>Association*</b>
            <b-form-select v-model="person.association" :style="isRequiredInput(person.association)"
                           :options="report.reportType === 'Lost Property' ? ['Victim', 'Witness', 'Mention'] : (report.reportType === 'Drug Violation' ? ['Witness', 'Mention'] : ['Victim', 'Witness', 'Mention'])"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Last Name*</b>
            <b-form-input v-model="person.last" :style="isRequiredInput(person.last)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>First Name*</b>
            <b-form-input v-model="person.first" :style="isRequiredInput(person.first)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Middle Initial</b>
            <b-form-input v-model="person.middleInt" maxLength="1"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Date of Birth*</b>
            <DateInput v-model="person.dob" :style="isRequiredInput(person.dob)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Sex*</b>
            <b-form-select v-model="person.sex" :options="['Male', 'Female', 'Other']" :style="isRequiredInput(person.sex)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Race*</b>
            <b-form-select v-model="person.raceType" style="width: 400px"
                           :options="[
                               'White',
                               'Hispanic or Latino',
                               'Black or African American',
                               'American Indian or Alaska Native',
                               'Asian',
                               'Native Hawaiian or Other Pacific Islander'
                           ]" :style="isRequiredInput(person.raceType)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Address</b>
            <b-form-input v-model="person.address"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Phone Number</b>
            <PhoneInput v-model="person.phone"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Email</b>
            <EmailInput v-model="person.email"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Descriptive Information</b>
            <b-form-textarea v-model="person.desc"/>
        </div>
        <div style="height: 24px"/>

        <template v-slot:modal-footer>
            <div class="d-flex justify-content-end w-100">
                <b-button size="sm" variant="secondary" @click="close" style="margin-right: 10px">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submit" :disabled="!ready">
                    Submit
                </b-button>
            </div>
        </template>

    </b-modal>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";
import PhoneInput from "@/components/PhoneInput";
import EmailInput from "@/components/EmailInput";
import DateInput from "@/components/DateInput";

export default {
    name: "AdditionalPerson",
    mixins: [FormMixin],
    components: {DateInput, PhoneInput, EmailInput},
    props: ['open', 'index'],
    data() {
        return {
            template: {
                association: null,
                first: null,
                last: null,
                middleInt: null,
                dob: null,
                sex: null,
                raceType: null,
                address: null,
                phone: null,
                email: null,
                desc: null,
            },
            person: null
        }
    },
    created() {
        if (this.index !== null && this.report.people
            && Array.isArray(this.report.people) && this.report.people[this.index]) {
            this.person = Object.assign({}, this.report.people[this.index]);
        } else {
            this.person = Object.assign({}, this.template);
        }
    },
    methods: {
        isEmail(val) {
            return EmailInput.methods.isEmail(val);
        },
        isNumber(val) {
            return PhoneInput.methods.isNumber(val);
        },
        submit() {
            this.addArrayElement('people', this.person, this.index);
            this.close();
        },
        close() {
            this.$emit('close');
            this.person = Object.assign({}, this.template);
        }
    },
    computed: {
        openModel() {
            return this.open;
        },
        ready() {
            return this.person.association && this.person.first && this.person.last
                    && ((this.person.email && this.isEmail(this.person.email)) || !this.person.email)
                    && ((this.person.phone && this.isNumber(this.person.phone)) || !this.person.phone)
                    && this.person.dob && this.person.sex && this.person.raceType;
        }
    }
}
</script>

<style scoped>
</style>


