<template>
    <div>

        <div v-for="(type, index) in reportTypes" style="margin-bottom: 5px" :key="index + 'type'">
            <div class="d-flex justify-content-between align-items-center rounded greyHover"
                 :style="highlight(type.val)" @click="report.reportType = type.val" style="cursor: pointer !important;">
                <div style="padding-left: 5px; font-size: 23px"><b>{{type.val}}</b></div>
                <b-form-radio v-model="report.reportType" :value="type.val" :class="filledOut"/>
            </div>
        </div>

        <br/>

        <div class="d-flex justify-content-between">
            <b-button size="sm" @click="changePage(1)">
                Back
            </b-button>
            <b-button size="sm" @click="changePageReportType(3)" :disabled="!report.reportType">
                Next
            </b-button>
        </div>

    </div>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";

export default {
    name: "ReportType",
    mixins: [FormMixin],
    data() {
        return {
            reportType: null,
            reportTypes: [
                {
                    val: 'Theft',
                    desc: 'To self-report you do not know the suspect. The theft cannot be a firearm, medication or ' +
                          'vehicle. The amount stolen must be under $2,500.00.'
                },
                {
                    val: 'Vandalism',
                    desc: 'To self-report you do not know the suspect. The property is not owned by the government. ' +
                          'The vandalism cannot be construed as a bias or hate crime.  The property damaged must be ' +
                          'under $2,500.00 to replace.'
                },
                {
                    val: 'Lost Property',
                    desc: 'To self-report the lost property cannot be a firearm, prescription medication, or vehicle.'
                },
                {
                    val: 'Fraud/Scam',
                    desc: 'To self-report you do not know the suspect.'
                },
                {
                    val: 'Drug Violation',
                    desc: 'To self-report it must not be occurring right now, or is on-going in nature.'
                },
                {
                    val: 'Shoplifting',
                    desc: 'To self-report you do not know the suspect. You do not have picture or video of the ' +
                          'suspect or license plate that is identifiable. The theft of merchandise is over $500.00.'
                }
            ],
            oldReportType: null,
            gettingStarted: [false, false, true, true, false, false],
        }
    },
    created() {
        if (this.report.reportType) {
            this.oldReportType = this.report.reportType;
        }
        this.addElements('reportType');
    },
    methods: {
        highlight(type) {
            return this.report.reportType === type ? 'background-color: #0079ff59' : '';
        },
        changePageReportType(page) {
            if (this.oldReportType !== null && this.oldReportType !== this.report.reportType) {
                Object.keys(this.report).forEach(key => ['reportType', 'gettingStarted'].includes(key) || delete this.report[key]);
            }
            this.changePage(page);
        }
    },
    computed: {
        filledOut() {
            return this.isRequiredRadio(this.report.reportType);
        }
    }
}
</script>

<style>
.disabledRadio .custom-control-label::before {
    border-color: red;
}

.greyHover:hover {
    background-color: #dadada !important;
}

</style>
