<template>
    <div id="app" :style="lightTheme ? '' : 'filter: invert(100%) !important'">
        <b-card no-body header-class="header" style="height: 100vh">
            <template v-slot:header style="margin: -12px -20px">
                <b-row style="width: calc(100% + 40px); border-bottom: #2c3e50; background-color: #ffffff; margin: -12px -20px">
                    <b-col lg="4">
                        <h4 class="d-flex align-items-center h-100">
                            <img style="height: 40px" src="../public/BeloitPoliceLogo.png"/>&nbsp;
                            <div><b>Beloit Police Department</b></div>
                        </h4>
                    </b-col>
                    <b-col lg="4" class="title-text">Self-Initiated On-Line Reporting</b-col>
<!--                    <b-col lg="4" class="d-flex justify-content-end align-items-center" style="margin: 5px 0">
                        <b-badge style="font-size: 1em; height: fit-content" variant="dark">
                            <fa v-if="lightTheme" icon="moon" @click="lightTheme = false"/>
                            <fa v-else icon="sun" @click="lightTheme = true"/>
                        </b-badge>
                    </b-col>-->
                </b-row>
            </template>
            <router-view style="background-color: lightgray"/>
        </b-card>

        <VuePNotify :options="{defaultDelay: 3000}"></VuePNotify>
    </div>
</template>

<script>
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Vue from 'vue'
import VuePNotify from 'vue-pnotify'

Vue.use(VuePNotify)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
export default {
    data() {
        return {
            lightTheme: true,
        }
    },
    created() {
        window.addEventListener("beforeunload", (e) => {
            e.preventDefault()
            const message = "You have unsaved changes. Are you sure you wish to leave?"
            e.returnValue = message
            return message
        });
    }
}
</script>

<style src="vue-pnotify/dist/vue-pnotify.css"/>
<style src="bootstrap/dist/css/bootstrap.css"/>

<style lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap-vue/src/index.scss';
</style>

<style>
.title-text {
    font-size: 2rem;
    font-weight: 925;
    text-align: center;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
