<template>
    <div>

        <b-modal v-model="confirmEmailModal" title="Email Conformation">
            <div>Email will be sent to {{$store.state.report.mainVictim.email}}</div>
            <template v-slot:modal-footer>
                <b-button size="sm" variant="danger" @click="reenterEmailAddress" :disabled="sendingEmailFlag">
                    Change
                </b-button>
                <b-button size="sm" variant="success" @click="sendEmail()" :disabled="sendingEmailFlag">
                    <fa v-if="sendingEmailFlag" icon="spinner" class="fa-spin"/>
                    {{sendingEmailFlag ? 'Sending' : 'Confirm'}}
                </b-button>
            </template>
        </b-modal>

        <b-modal v-model="reenterEmailModal" title="Please Enter New Email Address">
            <EmailInput v-model="newEmailAddress"/>
            <template v-slot:modal-footer>
                <b-button size="sm" variant="success" @click="sendEmail(newEmailAddress)" :disabled="sendingEmailFlag || !isEmail(newEmailAddress)">
                    <fa v-if="sendingEmailFlag" icon="spinner" class="fa-spin"/>
                    {{sendingEmailFlag ? 'Sending' : 'Confirm'}}
                </b-button>
            </template>
        </b-modal>

        <div class="d-flex justify-content-center align-items-center" style="gap: 25px; color: green">
            <fa icon="check-circle" style="font-size: 75px"/>
            <div class="d-flex flex-column">
                <b>Receive filled out incident in its entirety</b>
                <b>Self-initiated on-line reporting # {{$store.state.reportNumber}}</b>
            </div>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-center" style="gap: 10px">
            <b-button size="sm" @click="openPdfModel">Print</b-button>
            <b-button size="sm" @click="openConfirmEmailModal" :disabled="disableEmail">E-Mail</b-button>
        </div>
    </div>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";
import axios from 'axios'
import EmailInput from "../../components/EmailInput";
import reportingStore from "../../store";
export default {
    name: "Submitted",
    mixins: [FormMixin],
    props: ['open', 'index'],
    components: {
        EmailInput
    },
    data() {
        return {
            disableEmail: false,
            confirmEmailModal: false,
            sendingEmailFlag: false,
            reenterEmailModal: false,
            newEmailAddress: '',
        }
    },
    methods: {
        isEmail(val) {
            return EmailInput.methods.isEmail(val)
        },
        reenterEmailAddress() {
            this.confirmEmailModal = false;
            this.reenterEmailModal = true;
        },
        openConfirmEmailModal() {
            this.confirmEmailModal = true;
        },
        async sendEmail(newEmailAddress) {
            this.disableEmail = true;
            this.sendingEmailFlag = true;
            axios({
                method: 'post',
                url: 'https://beloitsrb.azurewebsites.net/sendemail',
                //for local testing
                //url: 'http://localhost:5006/sendemail',
                data: {
                    pdf: reportingStore.getters.getPdfDownload,
                    email: newEmailAddress !== undefined ? newEmailAddress : reportingStore.getters.getVictimEmail,
                    reportNumber: reportingStore.getters.getReportNumber,
                    rawReport: reportingStore.getters.getReport
                }
            }).then(() => {
                this.$notify({
                    title: 'Success',
                    text: "Email was successfully sent.",
                    style: 'success'
                });
                if (newEmailAddress !== undefined) {
                    this.reenterEmailModal = false;
                } else {
                    this.confirmEmailModal = false;
                }
            }).catch(error => {
                this.sendingEmailFlag = false;
                this.disableEmail = false;
                console.log(error);
                this.$notify({
                    title: 'Error',
                    text: "Failed to send email.",
                    style: 'error'
                });
            });
        },
        openPdfModel() {
            this.$bvModal.show('pdfPrintout')
        }
    }
}
</script>

<style scoped>

</style>
