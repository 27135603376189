import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let reportingStore = new Vuex.Store({

    state: {
        curPageIndex: 0,
        pdf: null,
        pdfDownload: null,
        report: {
          // reportType: "Theft"
          // reportType: "Vandalism"
          // reportType: "Lost Property"
          // reportType: "Fraud/Scam"
          // reportType: "Drug Violation"
          // reportType: "Shoplifting"
        },
        reportNumber: "404 No Report",
        /*report: {
            "gettingStarted": [false, false, true, true, false, false],
            "reportType": "Theft",
            "generalQuestions": [false, false, false],
            "incidentLocation": "1074 Brandywine Estates Drive Belvidere, IL 61008",
            "timeOfIncident": "00:00:00",
            "dateOfIncident": "[native Date Sun May 01 2022 00:00:00 GMT-0500 (Central Daylight Time)]",
            "mainVictim": {
                "association": "VICTIM",
                "first": "Dante",
                "last": "Galluzzo",
                "middleInt": "A",
                "dob": "[native Date Sun May 01 1998 00:00:00 GMT-0500 (Central Daylight Time)]",
                "address": "1074 Brandywine Estates Drive Belvidere, IL 61008",
                "phone": "8156012404",
                "email": "myjunkmail726982@yahoo.com",
                "prefCommunication": "By Email"
            },
            "people": [
                {
                    "association":"Victim",
                    "first":"Nate",
                    "last":"Camm",
                    "middleInt":"A",
                    "dob":"[native Date Thu May 07 1998 00:00:00 GMT-0500 (Central Daylight Time)]",
                    "sex":"Male",
                    "raceType":"White",
                    "address":"1234 Tyson Drive Loves Park, IL 61112",
                    "phone":"(123) 456-7890",
                    "email":"gayguy69@yahoo.com",
                    "desc":"Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face.Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face.Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face.Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face. Really dumb and has stupid face.Really dumb and has stupid face. "},{"association":"Victim","first":"1","last":"1","middleInt":"1","dob":"[native Date Sun May 01 2022 00:00:00 GMT-0500 (Central Daylight Time)]",
                    "sex":"Male",
                    "raceType":"White",
                    "address":"1",
                    "phone":"(111) 111-1111",
                    "email":"11111",
                    "desc":"11111"
                }
            ],
            "property": [
                {
                    "status": "Stolen",
                    "owner": "Dante",
                    "item": "Computer Processing Unit (CPU)",
                    "brand": "Intel",
                    "model": "i9-11900k",
                    "year": "2021",
                    "serialNumber": "BX8070811900K",
                    "quantity": "200,000",
                    "value": "77,998,000",
                    "desc": "Is Intel's new $539 Core i9-11900K desktop CPU made of silicon? Yes, of course it is. Does that mean, by extension here in 2021, it might end up tricky to find at Intel's recommended pricing? Very possible. The demand for high-end component hardware is practically baked in these days. So the Core i9-11900K, Intel's latest flagship processor and the head of its 11th Generation \"Rocket Lake\" line, should sell well. But it is not the rabbit that the chip giant needs to pull out of its hat after a few years spent trailing AMD in CPU innovation and raw performance. It's merely a pedestrian showing at a time when real magic is necessary to change the CPU conversation."
                }
            ],
            "vehicles": [
                {
                    "association":"Suspect",
                    "owner":"Owner",
                    "plate":"License Plate",
                    "type":"Automobile",
                    "typeOther":null,
                    "makeModel":"Make/Model",
                    "color":"Color",
                    "doors":"4 door",
                    "desc":"Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information Descriptive Information "
                }
            ],
            "hasPhotosOrVideo": false,
            "incidentDesc": "We the People of the United States, in Order to form a more perfect Union, establish Justice, insure domestic Tranquility, provide for the common defense, promote the general Welfare, and secure the Blessings of Liberty to ourselves and our Posterity, do ordain and establish this Constitution for the United States of America."
        }*/
    },
    mutations: {  // ReportingStore.commit('updateReport', nReport)
        UPDATE_REPORT(state, report) {
            state.report = report;
        },
        UPDATE_PAGE_INDEX(state, index) {
            state.curPageIndex = index;
        },
        UPDATE_REPORT_NUMBER(state, reportNumber) {
            state.reportNumber = reportNumber;
        },
        UPDATE_PDF(state, pdf) {
            state.pdf = pdf.pdf;
            state.pdfDownload = pdf.download;
        }
    },

    actions: {  // ReportingStore.dispatch
        updateReportNumber({commit, state}, reportNumber) {
            commit('UPDATE_REPORT_NUMBER', reportNumber);
        },
        updateReport({commit, state}, report) {
            commit('UPDATE_REPORT', report);
        },
        updatePdf({commit, state}, pdf) {
            commit('UPDATE_PDF', pdf);
        },
        updatePageIndex({commit, state}, index) {
            commit('UPDATE_PAGE_INDEX', index)
        },
        finalReportFilter({commit, state}) {
            let tempReport = JSON.stringify(state.report);
            let regex = /\\n|[\u200B]/g;
            let cleanedReport = tempReport.replace(regex, ' ');
            let updateReport = JSON.parse(cleanedReport);
            commit('UPDATE_REPORT', updateReport);
        }
    },

    getters: {  // ReportingStore.getters
        getReport(state) {
            return state.report;
        },
        getPageIndex(state) {
            return state.curPageIndex;
        },
        getVictimEmail(state) {
            if (state.report.mainVictim.email !== undefined) {
                return state.report.mainVictim.email;
            } else {
                return '';
            }
        },
        getReportNumber(state) {
            return state.reportNumber;
        },
        getPdf(state) {
            return state.pdf;
        },
        getPdfDownload(state) {
            return state.pdfDownload;
        }
    }
});

export default reportingStore;