<template>
    <div>

        <div class="d-flex flex-column align-items-center" style="color: red">
            <b>If this is an emergency please call 911</b>
            <b>If you have questions about self-reporting you can call 608-364-6801</b>
        </div>

        <br/>

        <div>
            <b>Purpose: </b> This form is used to report incidents that occur within the City of Beloit to the Beloit Police
            Department.  Report content will be considered for either future investigation or maintained as a police
            department record.  Self-initiated on-line reports that are deemed inappropriate, lack jurisdiction, or are
            otherwise not within the scope of police responsibility will be documented as not reportable.
        </div>

        <br/>

        <div>
            <b>Instructions: </b> Complete all applicable portions of this form.  Use full name(s) if known.  Describe
            property with all available identifiers including serial numbers, if known.  Explain the circumstances with
            details, including date, time and location.  Incomplete information will delay report processing.
        </div>

        <br/>

        <div class="d-flex justify-content-end">
            <b-button size="sm" @click="changePage(1)">Next</b-button>
        </div>

    </div>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";
export default {
    name: "Instructions",
    mixins: [FormMixin],
}
</script>

<style scoped>

</style>
