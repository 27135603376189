<template>
    <div>

        <b-modal v-model="areYouSureSubmitModal" hide-header>
            <div class="d-flex flex-column align-items-center">
                <h5><b>Are you sure you want to submit?</b></h5>
                <p>After submitting you will not be able to edit the report.</p>
            </div>
            <template v-slot:modal-footer>
                <b-button @click="areYouSureSubmitModal = false" size="sm">Close</b-button>
                <b-button size="sm" @click="changePage(7); sendPdEmail()" variant="success">Submit</b-button>
            </template>
        </b-modal>

        <div class="d-flex justify-content-between">
            <b>Do you have photos or videos to upload in regards to this report?*</b>
            <div>
                <b-form-radio-group v-model="report.hasPhotosOrVideo" :options="yesNoRadioOptions"
                                    :class="isRequiredRadio(report.hasPhotosOrVideo)"/>
            </div>
        </div>
        <sup v-if="report.hasPhotosOrVideo" style="color: green">
            An email will be sent to download the videos within 10 days
        </sup>
        <div v-else style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Please briefly describe this incident*</b>
            <b-form-textarea v-model="report.incidentDesc"
                             :style="isRequiredInput(report.incidentDesc)"
                             rows="6"
                             @input="() => removeEnterKey()"
                             no-resize
                             no-auto-shrink/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-center">
            <b-form-checkbox v-model="agree" :state="agree" :disabled="!report.incidentDesc">
                {{checkboxText}}
            </b-form-checkbox>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b-button size="sm" @click="changePage(5)">Back</b-button>
            <b-button size="sm" :disabled="!ready" @click="areYouSureSubmitModal = true" variant="success">Submit</b-button>
        </div>

    </div>
</template>

<script>

import {FormMixin} from "@/reportComponents/FormMixin";
import reportingStore from "@/store/index.js";
import axios from 'axios';

export default {
    name: "DescriptionOfIncident",
    mixins: [FormMixin],
    data() {
        return {
            agree: false,
            areYouSureSubmitModal: false,
            allCheckboxText: {
                'Theft':
                    'By submitting this report, I am stating I am the owner of the property that was stolen and did ' +
                    'not give anyone permission to use or take the reported property.',
                'Vandalism':
                    'By submitting this report, I am stating I am the victim of the property that was vandalized, or ' +
                    'am reporting in for a business.  I did not give anyone permission to vandalize the reported property.',
                'Lost Property':
                    'By submitting this report, I am stating I am the owner of the lost property.',
                'Fraud/Scam':
                    'By submitting this report, I am stating I am the victim of fraud.  I did not give anyone my ' +
                    'personal information for their own personal gain.',
                'Shoplifting':
                    'By submitting this report, I am stating I am the victim, or the business I am reporting on' +
                    'behalf of, did not give anyone permission to take the documented property without payment.',
                'Drug Violation':
                    'By submitting this report, I am stating all info in this report is the truth to my knowledge.'
            }
        }
    },
    created() {
        this.addElements({hasPhotosOrVideo: null, incidentDesc: ""});
    },
    methods: {
        sendPdEmail() {
            reportingStore.dispatch('finalReportFilter').then(() => {
                axios({
                    method: 'post',
                    url: 'https://beloitsrb.azurewebsites.net/sendpdemail',
                    //for local testing
                    //url: 'http://localhost:5006/sendpdemail',
                    data: {
                        rawReport: reportingStore.getters.getReport,
                    }
                }).then(response => {
                    if (response.data.response.status === 200) {
                        reportingStore.dispatch('updateReportNumber', response.data.reportNumber);
                        reportingStore.dispatch('updatePdf', response.data.pdf);
                    } else {
                        console.log('something went wrong', response)
                    }
                });
            });
        },
        removeEnterKey() {
            try {
                this.$store.state.report.incidentDesc = this.$store.state.report.incidentDesc.toString().replace(/(\r\n|\n|\r)/gm, " ");
            } catch (e) {}
        },
    },
    computed: {
        checkboxText() {
            if (this.allCheckboxText[this.report.reportType]) {
                return this.allCheckboxText[this.report.reportType];
            } else {
                console.log('Error: unknown report type');
                return '';
            }
        },
        ready() {
            return ((this.report.hasPhotosOrVideo === true || this.report.hasPhotosOrVideo === false)
                    && this.report.incidentDesc && this.report.incidentDesc.length && this.agree);
        },
    }
}
</script>

<style scoped>

</style>

