
<template>
    <div>
        <b-form-input v-model="input" :style="inputStyle || (!validInput && value ? 'border-color: red' : '')" style="position: relative"/>
        <b-form-text v-if="!validInput && value" text-variant="danger" class="helpText">Invalid Email</b-form-text>
    </div>
</template>

<script>
export default {
    name: "EmailInput",
    props: {
        value: String,
        inputStyle: String,
    },
    created() {
        this.validInput = this.isEmail(this.input);
        this.$emit('input', this.input)
    },
    data () {
        return {
            validInput: false,
            input: this.value
        }
    },
    methods: {
        isEmail(val) {
            return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(val)
        },
    },
    watch: {
        input() {
            this.validInput = this.isEmail(this.input);
            this.$emit('input', this.input)
        }
    }
}
</script>

<style scoped>
.helpText {
    position: absolute;
    transform: translateY(-5px);
}
</style>