<template>
    <b-modal v-model="openModel" title="Add Main Victim" size="lg" no-close-on-backdrop no-close-on-esc hide-header-close>

        <div class="d-flex justify-content-between">
            <b>Last Name*</b>
            <b-form-input v-model="person.last" :style="isRequiredInput(person.last)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>First Name*</b>
            <b-form-input v-model="person.first" :style="isRequiredInput(person.first)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Middle Initial*</b>
            <b-form-input v-model="person.middleInt" :style="isRequiredInput(person.middleInt)" maxLength="1"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Date of Birth*</b>
            <DateInput v-model="person.dob"  :style="isRequiredInput(person.dob)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Address{{person.prefCommunication === 'In Person' ? '*' : ''}}</b>
            <b-form-input v-model="person.address"
                          :style="person.prefCommunication === 'In Person' ? isRequiredInput(person.address) : ''"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Sex*</b>
            <b-form-select v-model="person.sex" :options="['Male', 'Female', 'Other']" :style="isRequiredInput(person.sex)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Race*</b>
            <b-form-select v-model="person.raceType" style="width: 400px"
                           :options="[
                               'White',
                               'Hispanic or Latino',
                               'Black or African American',
                               'American Indian or Alaska Native',
                               'Asian',
                               'Native Hawaiian or Other Pacific Islander'
                           ]" :style="isRequiredInput(person.raceType)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Phone Number{{person.prefCommunication === 'By Phone' ? '*' : ''}}</b>
            <PhoneInput v-model="person.phone" :inputStyle="person.prefCommunication === 'By Phone' ? isRequiredInput(person.phone) : ''"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Email*</b>
            <EmailInput v-model="person.email" :input-style="isRequiredInput(person.email)"/>
        </div>
        <div style="height: 24px"/>

        <div class="d-flex justify-content-between">
            <b>Preferred Method of Contact</b>
            <b-form-select v-model="person.prefCommunication" :options="['By Email', 'By Phone', 'In Person']"/>
        </div>
        <div style="height: 24px"/>

        <template v-slot:modal-footer>
            <div class="d-flex justify-content-end w-100">
                <b-button size="sm" variant="secondary" @click="close" style="margin-right: 10px">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submit" :disabled="!ready">
                    Submit
                </b-button>
            </div>
        </template>

    </b-modal>
</template>

<script>
import {FormMixin} from "@/reportComponents/FormMixin";
import reportingStore from "@/store/index.js";
import PhoneInput from "@/components/PhoneInput";
import EmailInput from "@/components/EmailInput";
import DateInput from "@/components/DateInput";

export default {
    name: "VictimInformation",
    mixins: [FormMixin],
    components: {DateInput, PhoneInput, EmailInput},
    props: ['open'],
    data() {
        return {
            template: {
                association: 'VICTIM',
                first: null,
                last: null,
                middleInt: null,
                dob: null,
                address: null,
                sex: null,
                raceType: null,
                phone: null,
                email: null,
                prefCommunication: 'By Email',
            },
            person: null
        }
    },
    created() {
        if (this.report.mainVictim) {
            this.person = Object.assign({}, reportingStore.getters.getReport.mainVictim);  // Modify existing main victim
        } else {
            this.person = Object.assign({}, this.template);  // Add new main victim
        }
    },
    methods: {
        isEmail(val) {
            return EmailInput.methods.isEmail(val);
        },
        isNumber(val) {
            return PhoneInput.methods.isNumber(val);
        },
        submit() {
            this.report.mainVictim = this.person;
            reportingStore.dispatch('updateReport', this.report);
            this.close();
        },
        close() {
            this.$emit('close');
            this.person = null;
        }
    },
    computed: {
        openModel() {
            return this.open;
        },
        ready() {
            let temp = this.person.first && this.person.last && this.person.middleInt && this.person.email && this.isEmail(this.person.email)
                        && this.person.sex && this.person.raceType
                        && ((this.person.phone && this.isNumber(this.person.phone)) || !this.person.phone) && this.person.dob;
            if (this.person.prefCommunication === 'In Person') {
                return temp && this.person.address;
            } else if (this.person.prefCommunication === 'By Phone') {
                return temp && this.person.phone && this.isNumber(this.person.phone);
            }  else {
                return temp;
            }
        }
    }
}
</script>

<style scoped>

</style>

