<template>
    <div class="contentContainer">
        <b-modal id="pdfPrintout" size="xl" hide-header-close title="PDF Printout">
            <PdfPrintout/>

            <template v-slot:modal-footer>
                <b-button @click="closeModal">Close</b-button>
            </template>
        </b-modal>

        <b-card header-tag="header" :style="curPageIndex === 5 ? 'width: 1300px' : 'width: 850px'">

            <!-- Getting Started -->
            <template v-slot:header>
                <h3 style="margin: 0; font-weight: bold; text-align: center">
                    {{ getHeader() }}
                </h3>
            </template>

            <!--Getting Started -->
            <instructions v-if="curPageIndex === 0"/>
            <getting-started v-if="curPageIndex === 1"/>
            <report-type v-if="curPageIndex === 2"/>

            <!-- Shared -->
            <general-questions v-if="curPageIndex === 3"/>
            <general-questions-cont v-if="curPageIndex === 4"/>
            <people-property-vehicle-overview v-if="curPageIndex === 5"/>
            <description-of-incident v-if="curPageIndex === 6"/>

            <!-- Submitted -->
            <submitted v-if="curPageIndex === 7"/>

        </b-card>
    </div>
</template>

<script>
import Instructions from "@/reportComponents/Instructions";
import GettingStarted from "@/reportComponents/GettingStarted";
import ReportType from "@/reportComponents/ReportType";
import GeneralQuestions from "@/reportComponents/Shared/GeneralQuestions"
import GeneralQuestionsCont from "@/reportComponents/Shared/GeneralQuestionsCont";
import PeoplePropertyVehicleOverview from "@/reportComponents/Shared/PeoplePropertyVehicleOverview";
import DescriptionOfIncident from "@/reportComponents/Shared/DescriptionOfIncident";
import Submitted from "@/reportComponents/Shared/Submitted";
import reportingStore from "@/store/index.js";
import PdfPrintout from "./PdfPrintout";

export default {
    name: "Reporting",
    components: {
        Instructions,
        GettingStarted,
        ReportType,
        GeneralQuestions,
        GeneralQuestionsCont,
        PeoplePropertyVehicleOverview,
        DescriptionOfIncident,
        Submitted,
        PdfPrintout
    },
    computed: {
        curPageIndex() {
            return reportingStore.getters.getPageIndex;
        },
        report() {
            return reportingStore.getters.getReport;
        }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('pdfPrintout')
        },
        getHeader() {
            switch (this.curPageIndex) {
                case 0:
                    return 'Instructions';
                case 1:
                    return 'Getting Started';
                case 2:
                    return 'Choose Report Type';
                case 3:
                    return this.report.reportType + ': General Questions';
                case 4:
                    return this.report.reportType + ': General Questions Cont.';
                case 5:
                    return this.report.reportType + ':  People, Vehicles, and Property';
                case 6:
                    return this.report.reportType + ': Description of Incident';
                case 7:
                    return 'Submitted';
                default:
                    return 'Error: Unknown Page';
            }
        },
    }
}
</script>

<style scoped>
    .contentContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
</style>

<style>
    .disabledRadio .custom-control-label::before {
        border-color: red;
    }
    .form-control, .custom-select {
        width: 400px !important;
    }
    .b-form-timepicker .form-control {
        width: 270px !important;
    }
    .b-form-timepicker .form-control, .b-form-datepicker .form-control {
        width: initial !important;
    }
</style>
