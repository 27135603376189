import reportingStore from "@/store/index.js";

export const FormMixin = {
    data() {
        return {
            yesNoRadioOptions: [
                {text: 'yes', value: true},
                {text: 'no', value: false}
            ],
            report: {},
            datePickerConfig: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            }
        };
    },
    created() {
       this.report = reportingStore.getters.getReport;
    },
    methods: {
        deleteArrayElement(key, index) {
            if (this.report[key] && Array.isArray(this.report[key]) && index !== null && index >= 0
                && this.report[key].length > index) {
                this.report[key].splice(index, 1);
            }
        },
        addArrayElement(key, ele, index) {
            if (!this.report[key] || !Array.isArray(this.report[key])) {
                this.$set(this.report, key, []);
                this.report[key].push(ele);
            } else if (index !== null && this.report[key].length > index && typeof ele === 'object') {
                this.$set(this.report[key], index, ele);
            } else {
                this.report[key].push(ele);
            }
        },
        addElements(data, obj) {
            obj = obj || this.report;
            if (typeof data === 'string') {
                if (!obj[data]) this.$set(obj, data, null);
            } else if (Array.isArray(data)) {
                data.forEach(e => {
                    if (!obj[e]) this.$set(obj, e, null);
                });
            } else {
                Object.keys(data).forEach(e => {
                    if (!obj[e]) this.$set(obj, e, data[e] || null);
                });
            }
        },
        changePage(page) {
            reportingStore.dispatch('updatePageIndex', page);
        },
        isRequiredRadio(e) {
            return e === undefined || e === null ? 'disabledRadio' : '';
        },
        isRequiredInput(e) {
            return e ? '' : 'border-color: red !important';
        },
    },
    watch: {
        report() {
            reportingStore.dispatch('updateReport', this.report);
        }
    }
}
