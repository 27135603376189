<template>
    <div :style="inputStyle" style="width: 400px" class="d-flex align-items-center border rounded"
         @click="openField()" :class="isFocused ? 'focusedBorder' : ''">
        <i class="fa fa-calendar-o fa-lg" style="margin: 6px 5px 6px 12px"/>
        <DatePicker v-model="input" :config="config" class="border-0" ref="datePicker" placeholder="No date selected"/>
    </div>
</template>

<script>

import DatePicker from 'vue-bootstrap-datetimepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


export default {
    name: "DateInput",
    components: {DatePicker},
    props: {
        value: String,
        inputStyle: String,
    },
    created() {
        this.input = this.value
    },
    data() {
        return {
            input: null,
            isFocused: false,
            config: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                focusOnShow: false,
                maxDate: Date.now()
            }
        };
    },
    methods: {
        openField() {
            this.isFocused = !this.isFocused
            if (this.isFocused) {
                this.$refs.datePicker.$el.focus()
            }
        },
    },
    watch: {
        input() {
            this.$emit('input', this.input)
        }
    }

}
</script>

<style scoped>
.focusedBorder {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.date-picker {
    outline: none !important;
}
input:focus {
    border-color: rgba(126, 239, 104, 0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0);
    outline: 0 none;
}

</style>